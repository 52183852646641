<template>
    <div class="tab-header">
        <labelc :icon-left="(tab.onlyAdmin) ? {icon: 'fa-user-shield'} : null" type="tab centered no-break" v-bind:class="{onlyAdmin: tab.onlyAdmin, selected: tab === tabSelected, margin: tab.margin, hidden: IsHidden(tab), hiddenButAdmin: IsHiddenButAdmin(tab)}" v-bind:style="{ width: (tab.width ? tab.width : 150) + 'px' }" :text="tab.label" v-for="(tab, index) in tabs" :key="tab.label" @click="TabSelect(tab)"/>
    </div>
</template>

<script>
    export default {
        name: "menutabs",
        data(){
            return {
                tabs: [],
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        props: {
            state: Object,
            tabSelected: Object,
            isSuperUser: Boolean,
        },
        methods:{
            TabSelect(tab){
                this.$emit('tab-select', tab);
            },
            IsHidden(tab){
                if(tab.hidden && !this.isSuperUser) {
                    if(Object.prototype.toString.call(tab.hidden) == '[object Function]') return tab.hidden();
                    else return tab.hidden;
                } else if(tab.show && !this.isSuperUser) {
                    if(Object.prototype.toString.call(tab.hidden) == '[object Function]') return !tab.show();
                    else return !tab.show;
                } else return false;
            },
            IsHiddenButAdmin(tab){
                if(tab.hidden && this.isSuperUser) {
                    if(Object.prototype.toString.call(tab.hidden) == '[object Function]') return tab.hidden();
                    else return tab.hidden;
                } else if(tab.show && this.isSuperUser) {
                    if(Object.prototype.toString.call(tab.hidden) == '[object Function]') return !tab.show();
                    else return !tab.show;
                } else return false;
            },
        }
    }
</script>

<style scoped>
    .tab-header{
        height: 35px;
        position: relative;
        margin-top: -15px;
    }

    .tab-header .tab{
        margin: 0 2.5px;
        cursor: pointer;
        align-content: center;
        height: 100%;
        padding: 0 10px;
    }

    .tab-header .tab:hover{
        background: var(--contrast-1);
        filter: brightness(120%);
    }

    .tab-header .tab:not(:hover){
        background: var(--contrast-8);
    }

    .tab-header.hover-gray .tab:hover{
        background: var(--contrast-3);
        filter: initial;
    }

    .tab-header .tab.selected{
        background: var(--contrast-1);
    }

    .tab-header .tab.onlyAdmin{
        background: var(--only-admin) !important;
    }

    .tab-header .tab.hiddenButAdmin{
        background: var(--only-admin) !important;
    }

    .tab.margin{
        margin-right: 0px;
    }

    .tab-header > .tab.hidden{
        display:none !important;
        pointer-events: none !important;
    }
</style>
